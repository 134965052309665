import {http, param} from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const userServices = {
    findUser(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'organizationId', val: payload.organizationId },
        ]
        return http.get(`${API_ROOT}/users${param(arr)}`).then(resp => {
            return resp
        })
    },
    getUser(id) {
        return http.get(`${API_ROOT}/users/${id}`).then(resp => {
            return resp
        })
    },
    getRoleNameData(id,orgId) {
        return http.get(`${API_ROOT}/users/${id}/${orgId}`).then(resp => {
            return resp
        })
    },
    updateUser(payload) {
        return http.put(`${API_ROOT}/users/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    createUser(payload) {
        return http.post(`${API_ROOT}/users`, payload).then(resp => {
            return resp
        })
    },
    resendVerificationEmail(id) {
        return http.put(`${API_ROOT}/users/${id}/invite`).then(resp => {
            return resp
        })
    },
    sendResetPasswordEmail(id) {
        return http.put(`${API_ROOT}/users/${id}/reset-password`).then(resp => {
            return resp
        })
    },
    updateStatus(payload) {
        return http.put(`${API_ROOT}/users/${payload.id}/status/${payload.status}/${payload.forceDeactivate == undefined ? false:true}`).then(resp => {
            return resp
        })
    },
    deleteUser(id) {
        return http.delete(`${API_ROOT}/users/${id}`).then(resp => {
            return resp
        })
    },
    getSitesMeta(organization_id) {
        return http.get(`${API_ROOT}/users/sitemeta?organization_id=${organization_id ? organization_id : ''}`).then(resp => {
            return resp
        })
    },
    getSitesMetaforusers(organization_id) {
        return http.get(`${API_ROOT}/users/userssitemeta?organization_id=${organization_id ? organization_id : ''}`).then(resp => {
            return resp
        })
    },
    getSitesMetaforProviderSelfRegistration(organization_id) {
        return http.get(`${API_ROOT}/account/userssitemeta?organization_id=${organization_id}`).then(resp => {
            return resp
        })
    },
    async getCentralizedIntakeData() {
        return http.get(`${API_ROOT}/users/centralized-intake-data`).then(resp => {
            return resp
        })
    },
    async getNotisphereOrgCentralizedIntakeData(orgId) {
        return http.get(`${API_ROOT}/users/organization/cii/${orgId}`).then(resp => {
            return resp
        })
    },
}

export default userServices
